<template>
  <div class="outer">
    <SearchPanel
      show-btn
      size="mini"
      flex
      @getList="getList()"
    >
      <!-- <div>
          <p>商家ID</p>
          <el-input
            v-model.trim="suppliersId"
            clearable
            placeholder="请输入商家ID"
            size="mini"
          ></el-input>
        </div> -->

      <div>
        <p>结算时间</p>
        <el-date-picker
          v-model="settlementTime"
          size="mini"
          type="datetimerange"
          align="right"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          :default-time="['00:00:00', '23:59:59']"
          @change="chooseSelectTime"
        />
      </div>
    </SearchPanel>

    <el-table
      ref="multipleTable"
      v-loading="loading"
      :data="tableData"
      border
      :header-cell-style="{
        background: '#D9DDE1',
        color: '#0D0202',
        fontWeight: 400
      }"
      element-loading-text="拼命加载中"
      style="width: 100%"
      @selection-change="handleSelectionChange"
    >
      <el-table-column
        label="结算时间"
        prop="settleDate"
      />
      <el-table-column
        label="平台"
        prop="projectCodeName"
      />
      <!-- <el-table-column label="商家名称" prop="supplierName" width="240"></el-table-column> -->
      <el-table-column
        label="订单编号"
        prop="orderNo"
        width="220"
      />
      <el-table-column
        label="交易流水号"
        prop="logSn"
        width="240"
      />
      <!-- <el-table-column label="支付流水号"></el-table-column> -->
      <!-- <el-table-column label="支付平台流水号" prop="payNo" width="240"></el-table-column> -->
      <el-table-column
        label="结算金额"
        prop="settleAmount"
        width="80"
      />
      <el-table-column
        label="结算状态"
        prop="settleStatus"
      >
        <template slot-scope="scope">
          {{ returnNongxinSettlementStatus(scope.row.settleStatus) }}
        </template>
      </el-table-column>
      <el-table-column
        label="结算类型"
        prop="settleType"
      >
        <template slot-scope="scope">
          {{ returnNongxinSettlementType(scope.row.settleType) }}
        </template>
      </el-table-column>
    </el-table>
    <div :style="{margin: '12px', display: 'flex', justifyContent: 'center', alignItems: 'center'}">
      <el-button
        :disabled="pagination.currentPage===1?true:false"
        size="mini"
        @click="prePage"
      >
        上一页
      </el-button>
      <div :style="{margin: '0 12px'}">
        当前页数:{{ pagination.currentPage }}
      </div>
      <el-button
        :disabled="pagination.nowPageSize>tableData.length?true:false"
        size="mini"
        @click="nextPage"
      >
        下一页
      </el-button>
    </div>
    <!-- <el-pagination
      v-if="tableData && tableData.length > 0"
      :current-page="pagination"
      :page-sizes="[3,10,20,30,50]"
      :page-size="pagination.nowPageSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="pagination.count"
      @size-change="handleSizeChange"
      @current-change="handleSizeChange"
      ></el-pagination> -->
  </div>
</template>

<script>

export default {
  components: {

  },
  data() {
    return {
      settlementTime: [],
      settlementBeginTime: '',
      settlementEndTime: '',
      loading: false,
      tableData: [],
      handerSelectionChange: '',
      pagination: {
        currentPage: 1,
        nowPageSize: 10,
        count: 0,
      },
    };
  },
  watch: {
    settlementTime(n, o) {
    //   console.log(n, o)
    //   console.log(typeof n)
    },
  },
  mounted() {
    // this.getList()
  },
  methods: {
    // 结算类型 00-支付01-退货02-提现03-扫码支付
    returnNongxinSettlementType(type) {
      const settlementTypes = ['支付', '退货', '结算入账', '扫码支付'];
      return settlementTypes[parseInt(type)];
    },

    // 返回结算状态
    returnNongxinSettlementStatus(statusValue) {
      const settlementStatus = ['未结算', '已结算'];
      return settlementStatus[parseInt(statusValue)];
    },
    handleSelectionChange() {},
    prePage() {
      if (this.pagination.currentPage > 1) {
        this.pagination.currentPage--;
        this.getList();
      }
    },
    nextPage() {
      this.pagination.currentPage++;
      this.getList();
    },
    chooseSelectTime(e) {
      this.settlementBeginTime = e[0].getTime();
      this.settlementEndTime = e[1].getTime();
    },
    getList() {
      if (!this.settlementTime) {
        this.$message.warning('请输入搜索信息');
        return;
      }
      this.$axios({
        methods: 'get',
        url: this.$api.settlement.settleDetail,
        params: {
          currentPage: this.pagination.currentPage,
          beginTime: this.settlementBeginTime,
          endTime: this.settlementEndTime,
          //   supplierId: this.suppliersId,
          pageSize: this.pagination.nowPageSize,
        },
      }).then((res) => {
        console.log(res);
        if (res.code === 0 && res.data) {
          this.tableData = res.data;
        }
      });
      console.log('获取结算单列表');
    },
    // 分页控制
    handleSizeChange(val) {
      this.pagination.nowPageSize = val;
      this.pagination.currentPage = 1;
      this.getList();
    },
    handleCurrentChange(val) {
      this.pagination.currentPage = val;
      this.getList();
    },
  },
};
</script>
